import { type Tier } from '@repo/database';
import { supportedIntegrationPascalCase } from './name-mapping';

export const TRIAL_PERIOD_DAYS = 14;

export const tierConstraints: Record<
  Tier,
  { maxUsers: number; maxIntegrations: number; maxRecency: number; order: number; features: string[] }
> = {
  Launch: {
    maxUsers: 1,
    maxIntegrations: 1,
    maxRecency: 30,
    order: 1,
    features: ['1 User Login', '1 platform only', 'Last 30d data'],
  },
  Build: {
    maxUsers: Infinity,
    maxIntegrations: Infinity,
    maxRecency: 365,
    order: 2,
    features: [
      `View ads across ${supportedIntegrationPascalCase.join(', ')}`,
      'Unlimited Accounts',
      'Last 365d data',
      'Create and assign accounts to Organisations',
    ],
  },
  Grow: {
    maxUsers: Infinity,
    maxIntegrations: Infinity,
    maxRecency: 365,
    order: 3,
    features: [
      'Creative Research Module: Find and save competitor ads',
      'Product Catalog Support (for DPA ads)',
      'Advanced business logic: trigger alerts',
      'Metadata enhancement through AI',
      'Make changes to ads',
    ],
  },
  Scale: {
    maxUsers: Infinity,
    maxIntegrations: Infinity,
    maxRecency: 365,
    order: 4,
    features: ['Custom integrations', 'Dedicated support', 'Advanced reporting'],
  },
};

export const canAddUser = (tier: Tier, userOrganizationCount: number): boolean =>
  userOrganizationCount < tierConstraints[tier].maxUsers;

export const isTierEqualOrLater = (orgTier: Tier, tier: Tier): boolean => {
  const tierOrder: Tier[] = ['Launch', 'Build', 'Grow', 'Scale'] as const;
  return tierOrder.indexOf(orgTier) >= tierOrder.indexOf(tier);
};

'use client';

import React, { useState, useEffect, type ReactNode } from 'react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { logger } from '@repo/logger';
import Image from 'next/image';
import { env } from '@/env.mjs';
import heroLight from '@public/images/hero/hero-new.avif';

function RotatingText({ texts, gradients }: { texts: string[]; gradients: string[] }): ReactNode {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % texts.length);
    }, 2_500);

    return () => {
      clearInterval(interval);
    };
  }, [texts]);

  return (
    <div className="relative inline-block m-0 w-full min-w-[50px] h-[1.5em] pointer-events-none">
      {texts.map((text, index) => (
        <span
          key={text}
          className={`whitespace-nowrap overflow-visible absolute left-0 right-6 text-center h-64 p-2 bg-clip-text text-transparent font-bold transition-all duration-1000 ease-in-out transform ${
            index === activeIndex ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-15'
          }`}
          style={{
            backgroundImage: gradients[index] || 'linear-gradient(to right, #ccc, #ddd)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
          }}
        >
          {text}
        </span>
      ))}
    </div>
  );
}

export default function Hero(): ReactNode {
  const [email, setEmail] = useState('');
  const t = useTranslations('hero');
  const router = useRouter();

  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    if (!email.includes('@')) {
      logger.error('Invalid email!');
      return;
    }
    router.push(`${env.NEXT_PUBLIC_WEBAPP_ENDPOINT}/sign-up?email=${encodeURIComponent(email)}`);
  };

  const analyzeTexts = ['Advertisments', 'Creatives', 'Competitors'];
  const analyzeGradients = [
    'linear-gradient(to right, #8e44ad, #3498db)',
    'linear-gradient(to right, #ff6b6b, #feca57)',
    'linear-gradient(to right, #11ff00, #00ff87)',
  ];

  return (
    <section className="overflow-visible pb-20 pt-35 md:pt-40 xl:pb-25 xl:pt-46">
      <div className="mx-auto px-4 md:px-8 2xl:px-0 overflow-visible">
        <div className="text-center overflow-visible">
          {/* Main Header with Rotating Text */}
          <div className="flex justify-center items-center overflow-hidden flex-wrap text-4xl font-bold text-black dark:text-white xl:text-[3.5rem] space-x-4">
            <span className="leading-tight whitespace-nowrap">Analyze your</span>
            <RotatingText texts={analyzeTexts} gradients={analyzeGradients} />
          </div>

          {/* Description */}
          <p className="mb-10 text-xl text-black dark:text-white">
            Stop over focusing on the numbers — Start obsessing over your creatives!
          </p>

          {/* Signup Form */}
          <form onSubmit={handleSubmit} className="max-w-xl mx-auto w-full">
            <div className="flex items-center justify-center flex-wrap gap-5 w-full">
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t('enterEmail')}
                className="w-[500px] rounded-full border border-stroke px-6 py-3 text-lg shadow-solid-2 focus:border-primary focus:outline-none dark:border-strokedark dark:bg-black dark:shadow-none dark:focus:border-primary"
              />
              <button
                type="submit"
                className="flex items-center justify-center rounded-full bg-gradient-to-r from-[#00c6ff] to-[#0072ff] bg-[200%] px-9 py-3 text-xl text-white transition-all duration-300 ease-in-out hover:shadow-md hover:translate-y-[-2px] active:scale-95"
              >
                {t('getStarted')}
              </button>
            </div>

            <p className="mt-5 text-black dark:text-white">{t('tryForFree')}</p>
          </form>

          {/* Hero Image */}
          <div className="flex justify-center items-center w-full">
            <Image src={heroLight} width={1000} height={1000} alt="Hero" className="shadow-sm rounded-md mt-10" />
          </div>
        </div>
      </div>
    </section>
  );
}

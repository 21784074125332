'use client';
import Image from 'next/image';
import { type ReactNode, useState } from 'react';
import { useFormatter, useTranslations } from 'next-intl';
import Link from 'next/link';
import { type CurrencyEnum, type BillingPeriodEnum, type Tier } from '@repo/database';
import { tierConstraints } from '@repo/mappings';
import { type GetPricingInfoQuery } from '@/graphql/generated/schema-server';
import { env } from '@/env.mjs';
import SectionHeader from '../Common/section-header';

interface PricingProps {
  pricingInfo: GetPricingInfoQuery['getPricingInfo'];
}

function Pricing({ pricingInfo }: PricingProps): ReactNode {
  const t = useTranslations('pricing');
  const format = useFormatter();
  const [billingCycle, setBillingCycle] = useState<BillingPeriodEnum>('Monthly');
  const [currency, setCurrency] = useState<CurrencyEnum>('USD' as CurrencyEnum);

  if (!pricingInfo) return null;

  const currencies = new Set(pricingInfo.map((info) => info.currency));

  const getAppBillingUrlParam = (tier: string): string => {
    const appUrl = env.NEXT_PUBLIC_WEBAPP_ENDPOINT;
    if (tier !== 'Scale') return `${appUrl}/sign-up?billingPeriod=${billingCycle}&currency=${currency}&tier=${tier}`;
    return 'mailto:info@adsviewer.io';
  };

  const getYearlySavedAmountWithCurrency = (tier: Tier): string => {
    const tierData = pricingInfo.find(
      (info) =>
        info.tier === tier && info.billingPeriod === ('Yearly' as BillingPeriodEnum) && info.currency === currency,
    );
    return format.number(
      Number(Number(tierData?.firstSeatCostPerMonth) / 100) * 12 - (Number(tierData?.firstSeatCostPerMonth) / 100) * 10,
      {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: tierData?.currency,
        maximumFractionDigits: 0,
      },
    );
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCurrency(e.target.value as CurrencyEnum);
  };

  interface TierInfo {
    tier: Tier;
    price: number | string | undefined | null;
    additionalPrice?: number | null;
    currency?: CurrencyEnum;
    description: string;
    features: string[];
    sso: boolean;
    actionBtnLabel?: string;
  }

  const tiers: TierInfo[] = [
    {
      tier: 'Launch',
      price: 'Free',
      description: t('tier1-packdescription'),
      features: [...tierConstraints.Launch.features],
      sso: false,
      actionBtnLabel: t('tier1-action-button'),
    },
    {
      tier: 'Build',
      price: pricingInfo.find(
        (info) => info.tier === ('Build' as Tier) && info.billingPeriod === billingCycle && info.currency === currency,
      )?.firstSeatCostPerMonth,
      additionalPrice: pricingInfo.find(
        (info) => info.tier === ('Build' as Tier) && info.billingPeriod === billingCycle && info.currency === currency,
      )?.subsequentSeatCostPerMonth,
      currency: pricingInfo.find(
        (info) => info.tier === ('Build' as Tier) && info.billingPeriod === billingCycle && info.currency === currency,
      )?.currency,
      description: t('tier2-packdescription'),
      features: [...tierConstraints.Build.features],
      sso: false,
    },
    {
      tier: 'Grow',
      price: pricingInfo.find(
        (info) => info.tier === ('Grow' as Tier) && info.billingPeriod === billingCycle && info.currency === currency,
      )?.firstSeatCostPerMonth,
      additionalPrice: pricingInfo.find(
        (info) => info.tier === ('Grow' as Tier) && info.billingPeriod === billingCycle && info.currency === currency,
      )?.subsequentSeatCostPerMonth,
      currency: pricingInfo.find(
        (info) => info.tier === ('Grow' as Tier) && info.billingPeriod === billingCycle && info.currency === currency,
      )?.currency,
      description: t('tier3-packdescription'),
      features: ['**Everything in Build, plus:**', ...tierConstraints.Grow.features],
      sso: false,
    },
    {
      tier: 'Scale',
      price: t('contactUs'),
      description: t('tier4-packdescription'),
      features: ['**Everything in Grow, plus:**', ...tierConstraints.Scale.features],
      sso: true,
      actionBtnLabel: t('contactUs'),
    },
  ];

  return (
    <section id="pricing" className="pt-20 lg:py-25 xl:py-30">
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        <div className="animate_top mx-auto text-center">
          <SectionHeader
            headerInfo={{
              title: t('title'),
              subtitle: t('subtitle'),
              description: t('description'),
            }}
          />

          <div className="relative flex flex-wrap gap-8 justify-center items-end mt-8 mb-12">
            <div className="flex">
              {/* Billing periods */}
              <button
                type="button"
                onClick={() => {
                  setBillingCycle('Monthly');
                }}
                className={`px-4 py-2 mr-2 rounded-l-lg ${
                  billingCycle === 'Monthly' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                {t('monthly')}
              </button>
              <button
                type="button"
                onClick={() => {
                  setBillingCycle('Yearly');
                }}
                className={`px-4 py-2 rounded-r-lg ${
                  billingCycle === 'Yearly' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                {t('yearly')}
              </button>
            </div>

            {/* Currency (we only support EUR & USD right now - based on Stripe's pricing info response) */}
            <div className="lg:absolute lg:right-13">
              <select
                id="currency-options"
                name="currency-options"
                className="rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-700 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                onChange={handleCurrencyChange}
                value={currency}
              >
                {Array.from(currencies).map((currencyOption) => (
                  <option key={currencyOption} value={currencyOption}>
                    {currencyOption}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mx-auto mt-15 max-w-[1207px] px-4 md:px-8 xl:mt-20 xl:px-0">
        <div className="absolute -bottom-15 -z-1 h-full w-full">
          <Image fill src="./images/shape/shape-dotted-light.svg" alt="Dotted" className="dark:hidden" priority />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-7.5 xl:gap-5">
          {tiers.map((tierData, index) => (
            <div
              key={tierData.tier}
              className="animate_top group relative rounded-lg border border-stroke bg-white p-7.5 shadow-solid-10 dark:border-strokedark dark:bg-blacksection dark:shadow-none flex flex-col"
            >
              <div className="flex flex-col">
                {/* Header and Description */}
                <div>
                  <h3 className="mb-7.5 text-3xl font-bold text-black dark:text-white xl:text-sectiontitle2">
                    {tierData.tier} {index === 2 && '🚀'}
                  </h3>

                  {typeof tierData.price !== 'string' ? (
                    <div className="mb-2.5">
                      <h4 className="whitespace-nowrap text-4xl font-bold text-black dark:text-white">
                        {format.number(Number(tierData.price) / 100, {
                          style: 'currency',
                          currencyDisplay: 'narrowSymbol',
                          currency: tierData.currency,
                          maximumFractionDigits: 0,
                        })}
                        <span className="text-sm font-normal mt-1 text-light-blue-500">/{t('monthShort')}</span>
                      </h4>
                      <p className="text-sm text-blue-500 mt-2">
                        +
                        {format.number(Number(tierData.additionalPrice) / 100, {
                          style: 'currency',
                          currencyDisplay: 'narrowSymbol',
                          currency: tierData.currency,
                          maximumFractionDigits: 0,
                        })}{' '}
                        {t('perAdditionalUser')}
                      </p>
                      {billingCycle === 'Yearly' && (
                        <p className="text-sm text-green-500 mt-1">
                          {t('saveAnnually', { save: getYearlySavedAmountWithCurrency(tierData.tier) })}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2.5">
                      <h4 className="whitespace-nowrap text-4xl font-bold text-black dark:text-white">
                        {tierData.price}
                      </h4>
                      {/* The <p> tags under this keep everything aligned with the rest of the pricing plans */}
                      <p className="invisible text-sm text-blue-500 mt-2">-</p>
                      {billingCycle === 'Yearly' ? (
                        <span className="invisible text-sm text-green-500 mt-1">-</span>
                      ) : null}
                    </div>
                  )}

                  <div className="h-25">
                    <p className="mb-6">{tierData.description}</p>
                  </div>
                </div>

                {/* Features */}
                <div className="border-t border-stroke pb-12.5 pt-9 dark:border-strokedark">
                  <ul>
                    {tierData.features.map((feature) => (
                      <li key={feature} className="mb-4 text-black last:mb-0 dark:text-manatee">
                        {feature.startsWith('**') ? (
                          <strong dangerouslySetInnerHTML={{ __html: feature.replace(/\*\*/g, '') }} />
                        ) : (
                          feature
                        )}
                      </li>
                    ))}
                    <li className="mb-4 text-black last:mb-0 dark:text-manatee">
                      <p className="text-xs text-gray-500">SSO</p>
                      <p>{tierData.sso ? 'Yes' : '-'}</p>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Button */}
              <div className="mt-auto">
                <button
                  type="button"
                  aria-label={`${t('contactUs')} button`}
                  className="group/btn inline-flex items-center gap-2.5 font-medium text-primary transition-all duration-300 dark:text-white dark:hover:text-primary"
                >
                  <Link className="duration-300 group-hover/btn:pr-2" href={getAppBillingUrlParam(tierData.tier)}>
                    {tierData.actionBtnLabel ? tierData.actionBtnLabel : t('getStarted')}
                  </Link>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.4767 6.16701L6.00668 1.69701L7.18501 0.518677L13.6667 7.00034L7.18501 13.482L6.00668 12.3037L10.4767 7.83368H0.333344V6.16701H10.4767Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;

import React from 'react';
import Script from 'next/script';
import { reflioCompanyId } from '@repo/utils';

export const CookiesReflio = (): React.ReactNode => {
  return (
    <Script
      src="https://reflio.com/js/reflio.min.js"
      data-domains="https://adsviewer.io/,https://app.adsviewer.io,https://www.adsviewer.io/"
      data-reflio={reflioCompanyId}
    />
  );
};
